.seaz-charter {
  &__edit {
    width: 126.4rem;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 96%;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 3rem 0 2.4rem;

      @media screen and (max-width: 1300px) {
        padding: 3rem 0 3.5rem;
      }

      @include tablet {
        flex-direction: column;
        justify-content: flex-start;
      }

      .seaz__return_btn {
        position: absolute;
        left: 0;
      }

      &_btn {
        position: absolute;
        right: 0;

        @include tablet {
          position: inherit;
          margin-top: 2.6rem;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-top: 3rem;

          .seaz__btn_primary {
            width: 100%;
          }
        }

        @media screen and (max-width: 480px) {
          margin-top: 3.8rem;
        }
      }

      &_title {
        text-transform: uppercase;
        text-align: center;
        color: $clr-primary-and-accent;
      }
    }

    &_attachments {
      &_row {
        display: flex;

        .seaz-charter__edit_form_uploader_btn {
          margin-left: 0;
        }
      }

      &_custom {
        width: 100%;
      }

      &_item {
        margin-bottom: 4rem;
      }

      &_name {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        color: $clr-primary-and-accent;
        font-weight: 500;
        margin-bottom: 2rem;
      }

      &_file {
        padding-bottom: 1rem;
        color: $seaz-document-count;
        word-break: break-word;
      }

      &_button {
        width: 2rem;
        height: 2rem;
        background-color: $clr-white;
        border-radius: 50%;
        color: $seaz-alarm;
        margin-left: 1rem;
      }

      &_label {
        display: block;
        margin-right: 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $seaz-text;
        max-width: 14rem;
        width: 100%;
      }
    }

    &_nav {
      display: flex;
      overflow: auto;
      max-width: 100%;
      padding-top: 2rem;

      &_wrapper {
        position: relative;
        padding-bottom: 4.8rem;
      }

      &_list {
        display: flex;
        margin: 0 auto;
      }

      app-scroll-controls .controls-container {
        top: -2rem;
        @include tablet-small {
          top: 0;
        }
      }

      a {
        padding: 1.8rem 3rem;
        color: $seaz-document-count;
        border: 1px solid $seaz-document-count;
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        display: inline-flex;
        font-weight: 500;
        position: relative;
        white-space: nowrap;
        @include tablet-small {
          width: auto;
          border: none;
          border-bottom: 2px solid $seaz-border;
          flex-shrink: 0;
        }

        &.active-item {
          color: $clr-primary-and-accent;
          border-color: $clr-primary-and-accent;
        }

        .nav-icon svg {
          fill: $seaz-warning;
        }

        app-seazone-icon {
          position: absolute;
          top: -1.1rem;
          right: 0.2rem;
          @include tablet-small {
            top: 0;
          }
        }
      }
    }

    &_form {
      .info-label {
        margin-bottom: 3rem;
      }

      &_controls {
        display: flex;
        width: 100%;
        @include mobile {
          flex-wrap: wrap;
        }

        &--broker {
          display: block;
        }
      }

      &_row {
        align-items: center;
        margin-bottom: 2.9rem;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }

        app-date-control {
          width: 15.2rem;
          margin-right: 1.1rem;
          flex-shrink: 0;
          @include tablet {
            width: 13.2rem;
          }
          @include mobile {
            width: 49%;
            margin-right: 2%;
          }

          input.seazone__form-date-input {
            font-size: 1.4rem;
          }

          .form-field--content {
            border-radius: 0;
          }

          .form-field--content-suffix {
            width: 32px;
            padding: 0;
            @include mobile {
              height: 24px;
            }
          }
        }

        app-seazone-time-input {
          width: 8.1rem;
          margin-right: 1.1rem;
          flex-shrink: 0;
          @include mobile {
            width: 49%;
            margin-right: 0;
          }

          input.seazone__form-input {
            font-size: 1.4rem;
          }

          .form-field--content {
            border-radius: 0;
          }

          .form__block-error {
            top: calc(100% + 0.1rem);
          }
        }

        app-location-timezone-autocomplete {
          width: 100%;
          @include mobile {
            margin-top: 1.4rem;
          }

          input.seazone__form-input {
            font-size: 1.4rem;
          }

          .form-field--content {
            border-radius: 0;
          }
        }
      }

      &_label {
        width: 15rem;
        margin-right: 1rem;
        flex-shrink: 0;
        color: $seaz-text;
        @media screen and (max-width: 650px) {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      app-event-period .seaz-event__period {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3rem;
      }

      &_area {
        app-seazone-text-area app-seazone-form-field.offset.seaz-text-area {
          margin-top: 0;

          .form-field--label {
            margin-right: 2rem;
            @media screen and (max-width: 650px) {
              margin-bottom: 1rem;
            }
          }

          .form-field--content-outline.form-field--content {
            border-color: $seaz-head-arrow;
            border-radius: 0;
            height: 15rem;
          }

          .invalid .form-field--content-outline.form-field--content {
            border-color: red;
          }

          .form-field--wrapper__row {
            @media screen and (max-width: 650px) {
              flex-direction: column;
            }
          }
        }

        app-seazone-text-area app-seazone-form-field.seaz-text-area {
          .form-field--wrapper.form-field__area .form-field--label {
            margin-right: 2rem;
            padding-top: 0;
            @media screen and (max-width: 650px) {
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }

          .form-field--wrapper.form-field__area {
            @media screen and (max-width: 650px) {
              flex-direction: column;
            }
          }
        }
      }

      .seaz-charter__broker-fee {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .seazone-radio-group__label {
          display: none;
        }
      }

      .seaz-charter__edit_form_row {
        app-seazone-select,
        app-seazone-input,
        app-seazone-text-area {
          .form-field--label.form-field--label {
            margin-right: 2rem;
            @media screen and (max-width: 650px) {
              margin-bottom: 1rem;
            }
          }

          .form-field--content {
            border-radius: 0;
          }

          .form-field--wrapper__row {
            @media screen and (max-width: 650px) {
              flex-direction: column;
            }
          }
        }
      }

      &_uploader {
        flex-direction: column;

        app-seazone-documents-uploader {
          .seaz-uploader__document {
            @include mobile {
              flex-direction: column;
            }

            .seaz-uploader__document_label {
              margin-right: 2rem;
              color: $seaz-text;
              @include mobile {
                width: auto;
                margin-bottom: 1rem;
              }
            }
          }
        }

        .form__checkbox.boat__form-checkbox {
          padding-left: 16rem;
          color: $seaz-text;
          @include mobile {
            padding-left: 0;
          }

          .form__checkbox-label {
            width: 2.4rem;
            height: 2.4rem;

            &::before {
              border-color: $seaz-mulberry;
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 0;
            }
          }

          .form__checkbox-input:checked + label:before {
            background-color: $seaz-mulberry;
          }
        }

        &_btn {
          margin-left: 16rem;
          @media screen and (max-width: 650px) {
            margin-left: 0;
          }
        }

        &_btns {
          display: flex;
          align-items: center;
          padding: 3rem 0;
          border-top: 1px solid $seaz-border;
          margin-top: 3rem;
          justify-content: flex-end;
          @media screen and (max-width: 500px) {
            flex-direction: column;
          }

          button {
            @media screen and (max-width: 500px) {
              width: 100%;
              margin-bottom: 1rem;
            }

            &:not(:last-of-type) {
              margin-right: 1.2rem;
              @media screen and (max-width: 500px) {
                margin-right: 0;
              }
            }
          }
        }
      }

      &_preferences {
        display: flex;
        align-items: center;
        @include mobile {
          align-items: flex-start;
        }

        &_label {
          width: 15rem;
          margin-right: 1rem;
          color: $seaz-text;
          @include mobile {
            margin-right: 0;
            width: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }

    &_payment {
      width: 100%;
      margin: 0 auto 4.8rem;

      .form__col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }

      .seaz-h3 {
        margin-right: 1.6rem;
      }
    }

    &_contract {
      width: 100%;

      .form__row {
        margin-top: 0.5rem;
      }
    }

    .seaz-charter__edit_form_payment-row {
      justify-content: space-around;
    }

    .form-field-label-gross-income {
      margin-right: 7.5rem;
    }
  }

  &__custom {
    &_title {
      padding-bottom: 3rem;
      color: $clr-primary-and-accent;
    }

    &_row {
      &.form__row {
        @include mobile {
          margin-bottom: 0rem;
        }
      }

      &.form__col {
        width: 100%;
        margin-bottom: 1.6rem;

        app-seazone-input app-seazone-form-field {
          .form-field--wrapper.form-field--wrapper__row {
            .form-field--label {
              margin-right: 3rem;
            }

            .form-field--content {
              border-radius: 0;
            }
          }
        }
      }

      app-seazone-documents-uploader {
        .seaz-uploader__document {
          @include mobile {
            flex-direction: column;
          }

          .seaz-uploader__document_label {
            margin-right: 3rem;
            @include mobile {
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    &_area {
      app-seazone-text-area app-seazone-form-field {
        .form-field--wrapper.form-field__area {
          .form-field--label {
            margin-right: 3rem;
          }

          .form-field--content {
            border-radius: 0;
          }
        }
      }
    }

    &_btns {
      padding: 3rem 0;
      border-top: 1px solid $seaz-border;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include mobile {
        margin-top: 3rem;
      }

      button {
        &:not(:last-of-type) {
          margin-right: 1.2rem;
        }
      }
    }
  }

  &__guests {
    width: 79.2rem;
    margin: 0 auto;
    padding-top: 7rem;

    &-modal {
      height: 80%;
    }

    @include laptop {
      width: 100%;
    }
    @include tablet {
      padding-top: 3rem;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 3rem;
      @include tablet {
        flex-direction: column;
        align-items: flex-start;
      }

      &_btn {
        flex-direction: row-reverse;
        @include tablet {
          padding: 0.9rem 3.2rem;
          width: 100%;
          justify-content: center;
        }

        &.seaz__btn_outlined_plus::after {
          margin-left: 0;
          margin-right: 0.8rem;
        }
      }
    }

    &_title {
      color: $clr-primary-and-accent;
    }

    app-seazone-input {
      padding-bottom: 3rem;
      border-bottom: 1px solid $seaz-border;
      margin-bottom: 5rem;
      @include tablet {
        padding-bottom: 4rem;
      }

      .form-field--content-outline.form-field--content {
        border-color: $seaz-head-arrow;
        border-radius: 0;

        .form-field--content-prefix {
          margin-right: 1.6rem;
        }
      }
    }

    &_list {
      width: 94%;
      margin: 0 auto;
      padding-bottom: 4rem;

      &_item {
        display: flex;
        align-items: flex-start;
        padding-bottom: 4.8rem;
        @media screen and (max-width: 600px) {
          padding-bottom: 4rem;
          flex-direction: column;
        }

        &_col {
          display: flex;
          flex-direction: column;
          width: 24%;
          align-items: flex-start;
          flex-shrink: 0;

          &:not(:last-of-type) {
            margin-right: 1rem;
          }

          @media screen and (max-width: 600px) {
            width: 100%;
            padding-bottom: 1.6rem;

            &:not(:last-of-type) {
              margin-right: 0;
            }
          }
        }

        &_label {
          text-transform: uppercase;
          color: $seaz-document-count;
          font-size: 1rem;
          letter-spacing: 0.5px;
          @media screen and (max-width: 600px) {
            padding-bottom: 0.8rem;
          }
        }

        &_name {
          color: $clr-primary-and-accent;
          font-size: 1.6rem;
          font-weight: 500;
          word-break: break-all;
        }

        &_btn {
          @include tablet {
            width: 87%;
            justify-content: center;
          }
          @media screen and (max-width: 600px) {
            width: 100%;
          }

          &_box {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }

          &--added {
            @media screen and (max-width: 768px) {
              width: 87%;
            }

            @media screen and (max-width: 600px) {
              width: 100%;
            }

            &.seaz__btn_small-outlined-plus {
              &::before {
                background-image: url('/assets/img/checked.svg');
              }

              color: $seaz-document-count;
              border-color: $seaz-border;
            }
          }
        }

        &_passport {
          display: flex;
          align-items: center;
          color: $clr-primary-and-accent;
          word-break: break-all;

          app-seazone-icon {
            margin-right: 1rem;
          }
        }
      }
    }

    &_add {
      &_box {
        width: 55%;
        margin: 0 auto;
        padding: 2rem 1.6rem;

        @media screen and (max-width: 1320px) {
          width: 77%;
        }
        @include laptop {
          width: 100%;
          padding: 2rem 0;
        }
        @include mobile {
          padding-top: 2rem;
        }
      }

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 3rem;
      }

      &_form {
        .form__row {
          app-seazone-input,
          app-seazone-select,
          app-date-control,
          app-seazone-text-area {
            .form-field--wrapper {
              flex-direction: row;
              @include tablet {
                flex-direction: column;
              }

              .form-field--content-suffix {
                @include mobile {
                  height: 103%;
                }
              }

              .form-field--label {
                margin-right: 1rem;
                width: 16rem;
                flex-shrink: 0;
                color: $seaz-text;
                @include tablet {
                  margin-right: 0;
                  width: 100%;
                  margin-bottom: 1rem;
                }
              }
            }
          }

          app-seazone-media-uploader {
            width: 100%;

            .seaz-uploader {
              flex-direction: row;
              @include tablet {
                flex-direction: column;
                margin-top: 0;
              }

              .seaz-uploader__choose {
                @include tablet {
                  justify-content: flex-start;
                }
                @include mobile {
                  width: 100%;
                }
              }

              .seaz-uploader__video_row {
                @include tablet {
                  width: 100%;
                }
                @include mobile {
                  margin-bottom: 2rem;
                  justify-content: flex-start;
                }

                app-seazone-image-file {
                  margin-right: 2rem;
                }
              }

              .seaz-uploader__box {
                @include mobile {
                  flex-direction: column;
                }
              }
            }

            .seaz-uploader__header {
              width: 16rem;
              margin-right: 1rem;
              flex-shrink: 0;
              @include tablet {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
              }

              .seaz-uploader__label {
                font-size: 1.4rem;
                line-height: 2rem;
                text-align: left;
                font-weight: 400;
              }
            }

            .yaht-edit__upload_img_box.editable:hover::before {
              border-radius: 8px;
            }

            .seaz-uploader__choose_drop {
              width: 100%;
              box-sizing: border-box;

              .seaz-uploader__choose_btn {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &_btns {
        margin-top: 3rem;
        align-items: center;
        //  justify-content: space-between;

        @include mobile {
          flex-wrap: wrap;
        }

        .btn_actions {
          @include tablet {
            width: 100%;
            margin-top: 1.6rem;
          }
        }

        &.seaz-crew__btn_box {
          @include tablet {
            width: 100%;
          }
          @include mobile {
            button {
              width: 100%;
              margin-top: 1rem;
            }
            .seaz-crew__btn {
              margin-right: 0;
            }
            .seaz-crew__btn:first-of-type {
              margin-right: 0;
              width: 100%;
            }
          }
        }

        app-seazone-checkbox {
          @include mobile {
            width: 100%;
          }

          .form__checkbox .form__checkbox-label {
            width: 2.4rem;
            height: 2.4rem;

            &::before {
              border-color: $seaz-border;
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 0;
            }
          }

          .form__checkbox .form__checkbox-input:checked + label:before {
            background-color: $seaz-mulberry;
            border-color: $seaz-mulberry;
          }

          .form__checkbox-label-text {
            padding-left: 0.8rem;
            color: $seaz-text;
          }

          .form__checkbox {
            margin-right: 1.6rem;
          }
        }
      }
    }

    &_form {
      width: 100%;
      padding-bottom: 5rem;

      &_title {
        padding-bottom: 1.6rem;
        font-size: 2rem;
        font-weight: 500;
        color: $seaz-text;
      }

      .info-label.light-purple {
        margin-bottom: 3rem;
      }

      app-seazone-input {
        width: 27%;
        @include laptop {
          width: 100%;
        }

        app-seazone-form-field {
          .form-field--wrapper.form-field--wrapper__row {
            .form-field--label {
              margin-right: 0.8rem;
              width: 16rem;
              max-width: 16rem;
              flex-shrink: 0;
              @include laptop {
                width: 27rem;
                max-width: 27rem;
              }
            }
          }
        }
      }

      &_count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.8rem 0;

        @include laptop {
          padding: 1.2rem 0;
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &_row {
          display: flex;
          align-items: center;
          overflow: hidden;

          @include laptop {
            width: 27rem;
            padding-right: 1rem;
            flex-wrap: wrap;
            line-height: 2rem;
            flex-shrink: 0;
            margin-right: 0.8rem;
          }
        }

        &_btn {
          @include laptop {
            width: 100%;
            margin-top: 1.7rem;
          }
        }

        &_text {
          margin-right: 1.6rem;
          font-size: 1.4rem;
          color: $clr-primary-and-accent;
          font-weight: 500;
          white-space: nowrap;
        }

        &_number {
          font-weight: 500;
          font-size: 2rem;
          color: $clr-primary-and-accent;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.error {
          .seaz-charter__guests_form_count_text,
          .seaz-charter__guests_form_count_number {
            color: $seaz-alarm;
          }
        }
      }

      &_table {
        &_cake {
          margin-left: 0.8rem;
        }

        &_head {
          display: flex;
          padding-bottom: 1.2rem;
          border-bottom: 1px solid $seaz-bg;
          padding-left: 2.4rem;
          padding-right: 2.4rem;
          color: $clr-light-grey;

          @include laptop {
            display: none;
          }

          &--kind {
            width: 16%;
            flex-shrink: 0;
          }

          &--name {
            width: 16%;
            flex-shrink: 0;
          }

          &--passport {
            width: 10%;
            flex-shrink: 0;
          }

          &--date {
            width: 9%;
            flex-shrink: 0;
          }

          &--country {
            width: 16%;
            flex-shrink: 0;
          }

          &--docs {
            width: 9%;
            flex-shrink: 0;
          }

          &--birth {
            width: 9%;
            flex-shrink: 0;
            white-space: nowrap;
          }
        }

        &_row {
          display: flex;
          align-items: center;
          padding: 2.9rem 2.4rem;

          &:nth-last-of-type(2n - 1) {
            background-color: $seaz-logo-border;
          }

          @include laptop {
            position: relative;
            flex-wrap: wrap;
          }
        }

        &_item {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          flex-shrink: 0;
          word-break: break-all;

          @include laptop {
            margin-bottom: 1.6rem;
          }

          &_label {
            display: none;
            @include laptop {
              display: flex;
              margin-bottom: 0.8rem;
              color: $seaz-document-count;
            }
          }

          &_box {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            app-seazone-icon {
              cursor: pointer;
              padding-top: 3px;
            }
          }

          &_count {
            height: 2.4rem;
            min-width: 2.4rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $seaz-head-arrow;
            color: $seaz-about-text;
          }

          app-seazone-icon {
            margin-right: 1rem;
          }

          &--kind {
            width: 16%;
            color: #010202;
            font-size: 1.6rem;

            @media screen and (max-width: 1300px) {
              width: 15%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--name {
            width: 16%;
            color: #010202;
            font-size: 1.6rem;

            @media screen and (max-width: 1300px) {
              width: 15%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--passport {
            width: 10%;
            font-size: 1.6rem;
            font-weight: 500;
            color: $clr-primary-and-accent;

            @media screen and (max-width: 1300px) {
              width: 11%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--date {
            width: 9%;
            font-size: 1.6rem;
            font-weight: 500;
            color: $clr-primary-and-accent;

            &.red {
              color: $seaz-alarm;
            }

            @media screen and (max-width: 1300px) {
              width: 11%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--country {
            width: 16%;
            font-size: 1.6rem;
            font-weight: 500;
            color: $clr-primary-and-accent;

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--docs {
            width: 8%;
            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--count {
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            background-color: $seaz-head-arrow;
            border-radius: 50%;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
            line-height: 2.4rem;
            color: $seaz-about-text;
          }

          &--birth {
            width: 10%;
            color: #010202;
            font-size: 1.6rem;
            white-space: nowrap;
            @media screen and (max-width: 1300px) {
              width: 11%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--btns {
            width: 15%;
            justify-content: flex-end;

            button {
              &:not(:last-of-type) {
                margin-right: 1rem;
              }
            }

            @include laptop {
              position: absolute;
              width: auto;
              bottom: 1.4rem;
              right: 1.4rem;
            }
            @include mobile {
              width: 100%;
              position: initial;
              justify-content: flex-start;
              padding-top: 1.8rem;
              border-top: 1px solid $seaz-head-arrow;
            }
          }
        }
      }

      &_box {
        background-color: $clr-white;
        padding: 2.4rem 1.2rem;
        border-radius: 1.6rem;
        margin-bottom: 3rem;
        @include laptop {
          padding-top: 0;
        }
      }

      .seaz-charter__add_btn_box {
        margin: 4.8rem auto 0;
      }

      @include mobile {
        .fleets-contacts-card-body__actions {
          width: 80%;
        }

        .fleets-contacts-card-body__btn {
          width: 100%;
        }
      }
    }

    &_view {
      position: relative;

      &_edit {
        position: absolute;
        top: 9rem;
        right: 6rem;
        @include mobile {
          top: 3rem;
        }
      }

      &_row {
        display: flex;
        padding-bottom: 3rem;

        &.red {
          color: $seaz-alarm;
        }

        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        &_label {
          width: 19rem;
          padding-right: 1rem;
          font-size: 1.6rem;
          font-weight: 300;
          color: $seaz-text;
          @include tablet {
            width: 12rem;
          }
          @include mobile {
            width: 100%;
            padding-bottom: 1rem;
          }
        }

        &_text {
          font-size: 1.6rem;
          color: $seaz-text;
        }

        &_icon {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          span {
            margin-left: 1rem;
            color: $seaz-document-count;
            padding-bottom: 0.2rem;
            border-bottom: 1px solid transparent;

            &:hover {
              color: $seaz-edit-hover;
              border-bottom: 1px solid $seaz-edit-hover;
            }
          }
        }

        &_list {
        }
      }
    }

    &_birth {
      margin-left: 1rem;
    }
  }

  &__container {
    width: 126.4rem;
    margin: 0 auto;
    padding-bottom: 4rem;
    @media screen and (max-width: 1300px) {
      width: 100%;
      max-width: 100rem;
    }
    @include laptop {
      padding: 0 0.8rem 4rem;
    }

    .app-charter-filter-form app-seazone-form-field .seazone-form-field--wrapper .form-field--label {
      margin: 0 0 0.4rem;
      font-size: 1.2rem;
      line-height: 14px;
      color: $seaz-about-text;
    }

    .seaz-charter-sorted__select app-seazone-form-field .seazone-form-field--wrapper .form-field--label {
      font-size: 1.4rem;
      margin-right: 1.6rem;
      color: $seaz-about-text;
    }
  }

  &__list {
    &_card {
      background-color: $clr-white;
      border-radius: 16px;
      margin-bottom: 1.6rem;
      padding: 1.6rem 0 0 0;

      &_head {
        padding: 0 3.2rem;
        @include laptop {
          padding: 0 1.6rem;
        }

        &_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.8rem;
          @include mobile {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        &_badges {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        &_last {
          color: $clr-light-grey;
          font-size: 1.2rem;
        }

        &_name {
          padding-bottom: 2.4rem;
          color: $clr-primary-and-accent;
          font-size: 2rem;
          font-weight: 500;
          line-height: 2.3rem;
          width: fit-content;

          &.disabled {
            color: $clr-light-grey;
          }
        }

        &_error {
          display: block;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          text-align: center;
          line-height: 2rem;
          background-color: $seaz-alarm;
          color: $clr-white;
          margin-right: 1rem;
          cursor: pointer;

          &_box {
            position: relative;

            &:hover {
              .seaz-charter__list_card_head_error_text {
                display: flex;
              }
            }
          }

          &_text {
            background-color: $clr-white;
            box-shadow: (0px 0px 12px rgba(53, 31, 115, 0.22));
            padding: 1.2rem 1.2rem 0.4rem 3rem;
            border-radius: 4px;
            color: $seaz-alarm;
            flex-direction: column;
            width: 22.6rem;

            span {
              list-style: disc;
              display: list-item;
              font-weight: 500;
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
          }
        }
      }

      &_details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3.2rem 2.4rem 3.2rem;
        @include laptop {
          flex-direction: column;
          padding: 0 1.6rem 1.6rem 1.6rem;
        }

        &_row {
          display: flex;
          width: 100%;
          @include laptop {
            flex-wrap: wrap;
          }
        }

        &_col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 2.4rem;
          flex-shrink: 0;

          @media screen and (max-width: 1300px) {
            padding-right: 1.5rem;
          }

          @include laptop {
            margin-bottom: 1.6rem;
          }
          @include mobile {
            padding-right: 0;
          }

          &:not(:last-of-type) {
            border-right: 1px solid $seaz-bg;
          }

          @include laptop {
            &:nth-last-of-type(2n) {
              border-right: none;
            }
          }

          &:not(:first-of-type) {
            padding-left: 2.4rem;

            @media screen and (max-width: 1300px) {
              padding-left: 1.5rem;
            }

            @include laptop {
              padding-left: inherit;
            }
          }

          @include laptop {
            &:nth-last-of-type(2n) {
              padding-left: 2.4rem;
            }
          }
          @include mobile {
            &:nth-last-of-type(2n) {
              padding-left: 0;
            }
          }

          &--period {
            width: 16%;
            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--start {
            width: 27%;
            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--end {
            width: 27%;
            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--guests {
            width: 10%;
            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--net {
            width: 16%;

            @media screen and (max-width: 1300px) {
              width: 17%;
            }

            @include laptop {
              width: 50%;
            }
            @include mobile {
              width: 100%;
            }
          }

          &--button {
            display: flex;
            justify-content: flex-end;
            width: 16.5%;

            @include laptop {
              width: 100%;
            }
          }
        }

        &_label {
          padding-bottom: 0.6rem;
          font-size: 1.2rem;
          color: $seaz-document-count;
        }

        &_text {
          font-size: 1.4rem;
          color: $seaz-text;
          line-height: 1.4;
        }

        &_btn {
          flex-shrink: 0;
          @include laptop {
            width: 100%;
            margin-top: 1.6rem;
            padding: 0.8rem 1.6rem;
          }
        }
      }

      &_footer {
        background: $seaz-logo-border;
        border-radius: 0 0 1.6rem 1.6rem;
        padding: 1.6rem 3.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include laptop {
          padding: 1.6rem;
          flex-direction: column;
          align-items: flex-start;
        }

        &_row {
          white-space: nowrap;

          @include laptop {
            margin-bottom: 0.8rem;
          }
          @include mobile {
            display: flex;
            flex-direction: column;
          }
        }

        &_value {
          font-size: 2rem;
          padding-right: 0.8rem;
          font-weight: 500;
          color: $clr-primary-and-accent;

          &.value-success {
            color: $seaz-success;
          }

          &.error {
            color: $seaz-alarm;
          }

          @include mobile {
            margin-bottom: 0.4rem;
          }
        }

        &_text {
          color: $seaz-document-count;
          font-size: 1.2rem;
        }

        &_btns {
          display: flex;
          align-items: center;
          @include laptop {
            width: 100%;
          }
          @include mobile {
            flex-direction: column;
          }

          &--apa {
            width: 100%;
            justify-content: flex-end;
          }

          button {
            @include laptop {
              width: 49%;
              display: flex;
              justify-content: center;
            }
            @include mobile {
              width: 100%;
            }
          }

          button:not(:last-of-type) {
            margin-right: 1.6rem;
            @include laptop {
              margin-right: 2%;
            }
            @include mobile {
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }

      &--data {
        .seaz-charter__list_card_details_col--guests {
          width: 9%;
          @include laptop {
            width: 50%;
          }
          @include mobile {
            width: 100%;
          }
        }

        .seaz-charter__list_card_details_col--period {
          width: 10%;
          @include laptop {
            width: 50%;
          }
          @include mobile {
            width: 100%;
          }
        }

        .seaz-charter__list_card_details_col--start,
        .seaz-charter__list_card_details_col--end {
          width: 22%;
          @include laptop {
            width: 50%;
          }
          @include mobile {
            width: 100%;
          }
        }
      }
    }

    &_header {
      display: flex;
      align-items: center;
      width: 126.4rem;
      margin: 0 auto;
      padding: 3.2rem 0 4.2rem 0;

      @media screen and (max-width: 1300px) {
        width: 100rem;
      }
      @include laptop {
        width: 98%;
        flex-wrap: wrap;
        padding: 2rem 0;
      }

      .boat__card-wrap {
        margin-bottom: 0;
        width: 100%;
      }

      .profile__wrap-img {
        width: 10rem;
        height: 10rem;
        @include laptop {
          width: 5.6rem;
          height: 5.6rem;
        }

        .avatar__img {
          width: 10rem;
          height: 10rem;
          @include laptop {
            width: 5.6rem;
            height: 5.6rem;
          }
        }
      }

      .seaz__return_btn {
        width: 4rem;
        height: 4rem;
        margin-right: 1.6rem;
        flex-shrink: 0;

        @include laptop {
          margin: 0 0 1.6rem;
        }
      }

      .profile__col_info {
        padding-right: 3.2rem;
        border-right: 1px solid #35287133;
        @include laptop {
          border-right: 0;
        }
      }

      &_apa {
        margin-left: 3.2rem;
        width: 100%;
        overflow: hidden;
        @media screen and (max-width: 1300px) {
          margin-left: 1.2rem;
        }
        @include laptop {
          padding-top: 1.6rem;
          border-top: 1px solid rgba(53, 40, 113, 0.2);
          margin-left: 0;
          margin-top: 2rem;
        }

        &_row {
          display: flex;
          align-items: center;
          @include laptop {
            flex-wrap: wrap;
          }
        }

        &_name {
          margin: 0 0.8rem;
          color: $clr-primary-and-accent;
          font-weight: 500;
          font-size: 2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          line-height: 2.4rem;
          @include laptop {
            width: 100%;
            margin: 1.2rem 0;
            white-space: normal;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 0.4rem;

          &_row {
            display: flex;
            align-items: flex-start;
            margin-top: 1rem;

            @include laptop {
              width: 100%;
              margin-top: 0;
            }
          }

          &_date {
            color: $seaz-about-text;
          }

          &_text {
            color: $seaz-about-text;
          }

          &_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include tablet {
              flex-direction: column;
              align-items: flex-start;

              .seaz-charter__btn_primary--plus {
                width: 100%;
                margin-top: 1.2rem;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      &_btns {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
        align-items: flex-end;

        @include laptop {
          flex-direction: row;
          justify-content: flex-end;
          // width: 100%;
          margin-top: 2.8rem;
        }

        button.seaz__btn_primary {
          width: 17.3rem;
          padding: 1rem 3.2rem;
          border-radius: 4px;

          &:not(:last-of-type) {
            margin-bottom: 1rem;
            @include laptop {
              margin-bottom: 0;
            }
          }

          @include laptop {
            width: 100%;
          }
        }
      }
    }

    &_nav {
      width: 100%;
      display: flex;
      border-bottom: 1px solid $seaz-border;
      overflow: auto;

      &_wrapper {
        display: flex;
        margin: 0 auto;
      }

      &_item {
        font-weight: 500;
        text-align: center;
        font-size: 1.6rem;
        padding: 1.7rem 3.2rem;
        color: $seaz-document-count;
        white-space: nowrap;

        &.active {
          border-bottom: 2px solid $clr-primary-and-accent;
          color: $clr-primary-and-accent;
        }
      }
    }

    &_data {
      &_btns {
        width: 100%;
        padding: 2.4rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          height: 4.2rem;

          &:not(:last-of-type) {
            margin-right: 1.2rem;
          }
        }

        @include mobile {
          flex-direction: column;

          button {
            width: 100%;
            justify-content: center;

            &:not(:last-of-type) {
              margin: 0 0 1.2rem 0;
            }
          }
        }
      }

      &_row {
        display: flex;
        justify-content: space-between;

        @include laptop {
          flex-direction: column;
        }

        app-charter-payment-details {
          margin-right: 1.6rem;
          @include laptop {
            margin-right: 0;
            margin-bottom: 1.6rem;
          }
        }

        &--services {
          width: 100%;

          .seaz-charter__list_payment {
            height: 100%;
          }

          app-charter-contact-person {
            display: block;
            margin-top: 1.6rem;

            @include laptop {
              margin-top: 0;
            }
          }

          &:not(:last-of-type) {
            margin-right: 1.6rem;
          }
        }

        &--services-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
        }
      }

      &_guests {
        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3.2rem 0;

          &.active {
            ~ .seaz-charter__list_data_guests_table {
              display: block;
            }
          }

          @include laptop {
            flex-direction: column;
            align-items: flex-start;
            padding: 3.2rem 1rem;
          }
        }

        &_table {
          display: none;
        }

        &_title {
          margin-left: 10px;
          font-size: 3rem;
          font-weight: 500;
          color: $clr-primary-and-accent;
          text-transform: uppercase;
          padding: 1rem 1rem 1rem 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &--text {
            overflow: hidden;
          }

          &.error {
            color: $seaz-alarm;
          }

          @include mobile {
            margin-bottom: 1.2rem;
          }
        }

        &_wrap {
          display: flex;
          overflow: hidden;

          //@media screen and (max-width: 600px) {
          //  padding-bottom: 3rem;
          //}
        }

        &_btns {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .guests-btn {
            &__crew {
              @include cut-text-add-dots;
            }
          }

          @include laptop {
            .guests-btn {
              width: 100%;
              justify-content: center;
            }
          }

          button:not(:last-of-type) {
            margin-right: 1.6rem;
          }
        }

        .toggle-button {
          transform: rotateX(180deg);
          padding-bottom: 0;
          transition: all 0.1s ease-in-out;
          padding-top: inherit;
          position: relative;
          top: inherit;
          bottom: -6px;

          &.active {
            transform: rotateX(0deg);
            top: 2px;
          }
        }
      }
    }

    &_payment {
      background-color: $clr-white;
      border-radius: 16px;
      padding: 2rem 2.4rem;

      @media screen and (max-width: 1024px) {
        padding: 1.6rem;
      }

      &_head {
        padding-bottom: 2rem;
        border-bottom: 1px solid $seaz-bg;
        display: flex;
        justify-content: space-between;
        color: $clr-primary-and-accent;
        align-items: center;

        &_title {
          font-size: 1.3rem;
          font-weight: 500;
          text-transform: uppercase;
        }
      }

      &_main {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.4rem 0 0.6rem;
        border-bottom: 1px solid $seaz-head-arrow;
        position: relative;
        width: 95%;
        margin: 0 auto;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        &_head {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &_subtitle {
          padding-bottom: 1.6rem;
          color: $seaz-document-count;
          font-size: 1.2rem;
        }

        &_title {
          color: $clr-primary-and-accent;
          font-weight: 500;
          padding-bottom: 0.2rem;
          font-size: 2rem;

          &.error {
            color: $seaz-alarm;
          }
        }

        &_arrow {
          width: 0.9rem;
          transform: rotate(180deg);
          position: absolute;
          bottom: 2.9rem;
          right: 0.8rem;
        }

        &_list {
          width: 95%;
          margin: 0 auto;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.2rem 0.8rem;

            app-seazone-icon.arrow-next {
              width: 9px;
              transform: rotate(180deg);
              margin-left: 1.6rem;
            }

            &--net-income {
              border-top: 1px solid #e0e0e0;
              border-bottom: 1px solid #e0e0e0;

              .seaz-charter__list_payment_main_list_text,
              .seaz-charter__list_payment_main_list_number {
                font-size: 1.8rem;
                font-weight: 500;
                color: $clr-light-grey;
              }
            }
          }

          &_text {
            padding-right: 1rem;
            color: $seaz-about-text;
            font-size: 1.4rem;

            &_box {
              display: flex;
              align-items: center;
            }
          }

          &_number {
            display: flex;
            align-items: center;
            color: $clr-light-grey;
            font-size: 1.4rem;
            font-weight: 500;
            white-space: nowrap;

            &_box {
              display: flex;
              align-items: center;
              position: relative;
            }

            &--positive {
              color: #3f9795;
            }

            &--negative {
              color: $button-main;

              .seaz-charter__list_payment_main_list_icon {
                transform: rotateX(180deg);
                transform-origin: 50% 50%;
              }
            }

            &.positive {
              color: #3f9795;
            }

            &.error {
              color: $seaz-alarm;
            }

            &.negative {
              color: $button-main;

              .seaz-charter__list_payment_main_list_icon {
                transform: rotateX(180deg);
                transform-origin: 50% 50%;
              }
            }
          }

          &_badge {
            margin-left: 0.8rem;
            color: $seaz-about-text;
            text-transform: uppercase;
            padding: 0.7rem 1.2rem;
            border-radius: 24px;
            background-color: $seaz-head-arrow;
            font-weight: 500;
          }

          &_count {
            display: block;
            height: 2.4rem;
            text-align: center;
            line-height: 2.4rem;
            min-width: 2.4rem;
            border-radius: 50%;
            color: $seaz-about-text;
            background-color: $seaz-head-arrow;
            margin-right: 0.8rem;
            font-weight: 500;
          }
        }
      }

      &_enable {
        width: 100%;
        justify-content: center;
        margin-bottom: 1rem;
      }
    }

    &_docs {
      background-color: $clr-white;
      box-shadow: 0 2px 5px rgb(0 0 0 / 4%);
      border-radius: 16px;
      height: 100%;

      &_head {
        padding: 2rem 2.4rem;
        border-bottom: 1px solid $seaz-bg;
        display: flex;
        justify-content: space-between;
        color: $clr-primary-and-accent;
        align-items: center;

        &_title {
          font-size: 1.3rem;
          font-weight: 500;
          text-transform: uppercase;
        }
      }

      &_list {
        padding: 2rem 0;

        &_item {
          padding: 1.8rem 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e0e0e0;

          &:not(:last-of-type) {
            border-bottom: 1px solid $seaz-head-arrow;
          }

          &_row {
            display: flex;
            align-items: center;
          }

          &_text {
            display: flex;
            flex-direction: column;
            margin-left: 1.8rem;

            &_title {
              font-size: 1.6rem;
              font-weight: 500;
              word-break: break-word;
              padding-bottom: 0.2rem;
              color: $clr-primary-and-accent;
            }

            &_subtitle {
              color: $seaz-document-count;
              font-size: 1.4rem;
              padding-right: 2rem;
              word-break: break-all;
              cursor: pointer;
            }
          }
        }

        @include tablet {
          padding: 2rem 1rem;
        }
      }
    }

    .nav-panel__link {
      line-height: 2.5rem;
    }
  }

  &__list-board {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    // margin-right: 1.6rem;

    @media screen and (max-width: 1024px) {
      margin: 0 0 1.6rem;
    }

    &--title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $seaz-bg;
      padding: 2rem 2.4rem;

      &--actions {
        .seaz-charter__list-board--title--action {
          @include indent-not-last-of-type(0 1.6rem 0 0);
        }
      }
    }

    &--title {
      font-size: 1.3rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $clr-primary-and-accent;
    }

    &--content {
      padding: 2rem 2.4rem 2.4rem;
    }

    &--content-broker {
      padding: 2rem 0 2.4rem;
    }
  }

  &-utc__tooltip.tooltip-pane,
  &-date__tooltip.tooltip-pane {
    box-shadow: (0px 0px 12px rgba(53, 31, 115, 0.22));

    .tooltip {
      padding: 1.2rem;
      border-radius: 4px;
      position: relative;
      display: inline-block;
      box-sizing: content-box;

      .tooltip__text {
        color: $seaz-about-text;
        letter-spacing: 0.01em;
        font-weight: 500;
      }
    }
  }

  &-utc {
    &__tooltip.tooltip-pane {
      &.top {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% - 6px));
          }
        }
      }

      &.bottom {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% - 6px)) rotate(180deg);
          }
        }
      }
    }
  }

  &-date {
    &__tooltip.tooltip-pane {
      &.top {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% + 5px));
          }
        }
      }

      &.bottom {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% + 5px)) rotate(180deg);
          }
        }
      }
    }
  }

  &__btn_box {
    @media screen and (max-width: 560px) {
      flex-wrap: wrap;
      button {
        width: 48%;

        &:nth-of-type(2n) {
          margin-right: 4%;
        }
      }
    }

    .seaz-charter__btn_delete {
      margin: 0 auto 0 0;
      @media screen and (max-width: 560px) {
        margin: inherit;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  &__filtering {
    padding: 1.2rem 1.6rem;
    margin: 4.8rem 0;
    border: 1px solid rgba(53, 40, 113, 0.2);

    @media screen and (max-width: 1024px) {
      margin: 3.2rem 0;
    }

    &--title {
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $seaz-document-count;
      margin-top: 2.4rem;
    }

    &--list {
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(53, 40, 113, 0.2);
      padding-top: 0.8rem;
      margin-top: 0.8rem;

      @media screen and (max-width: 1024px) {
        flex-direction: row;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    }

    &--row {
      display: flex;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
      }
    }

    &--item {
      width: 24%;

      &:not(:last-of-type) {
        border-right: 1px solid rgba(53, 40, 113, 0.2);
        margin-right: 1.6rem;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;

        &:not(:last-of-type) {
          border-right: 0;
          margin-right: 0;
          margin-bottom: 2.3rem;
        }
      }
    }

    &--hr {
      border-top: 1px solid rgba(53, 40, 113, 0.2);
      margin: 1.6rem 0;

      @media screen and (max-width: 1024px) {
        border-top: 0;
        border-right: 1px solid rgba(53, 40, 113, 0.2);
        margin: 0 1.6rem;
      }

      @media screen and (max-width: 480px) {
        border-top: 1px solid rgba(53, 40, 113, 0.2);
        border-right: 0;
        margin: 1.6rem 0;
      }
    }

    &--content {
      display: flex;
      align-items: center;
    }

    &--num {
      font-size: 20px;
      font-weight: 500;
      color: $clr-primary-and-accent;
      margin-right: 1.2rem;
    }

    &--text {
      color: $seaz-document-count;
    }

    .seaz-charter__list_card_head_error {
      margin-left: 1.3rem;
      background-color: #a2a2a2;
      padding-left: 0;
      margin-right: 0;

      @media screen and (max-width: 768px) {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.2rem;
      }
    }
  }

  &__guests_form-count {
    display: flex;
    align-items: center;

    &--btn {
      margin-left: 0.8rem;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;

      &--btn {
        width: 100%;
        margin: 1.7rem 0 0 0;
      }
    }
  }

  &__guests_form-sortBy {
    display: none;
    margin-bottom: 2.4rem;

    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.seaz-charter-card-badge {
  padding: 0.7rem 1.2rem;
  border-radius: 2.4rem;
  text-transform: uppercase;
  margin: 0 0.8rem 0 0;
  letter-spacing: 0.1em;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
  @include laptop {
    padding: 0.3rem 1.2rem;
  }
  @include mobile {
    margin-bottom: 0.8rem;
  }

  &.charter-type-past-charter {
    background-color: $seaz-alarm-bg;
    color: $seaz-alarm;
  }

  &.charter-type-owners-charter {
    color: $clr-white;
    background-color: $clr-green;
  }

  &.charter-type-all-inclusive {
    color: $clr-white;
    background-color: $clr-blue;
  }

  &.charter-type-charter {
    color: $clr-white;
    background-color: $clr-primary-and-accent;
  }

  &.charter-type-trip {
    color: $clr-white;
    background-color: $seaz-edit-hover;
  }

  &.charter-type-running {
    color: $seaz-success;
    background-color: #d8efd4;
  }

  &.charter-type-upcoming {
    color: #94792b;
    background-color: #fdefc8;
  }

  &.charter-complete {
    color: $seaz-about-text;
    background-color: $seaz-head-arrow;
  }
}

app-charter-wrapper {
  display: block;
  background-color: $seaz-bg;
}

.seaz-charter-error__tooltip.tooltip-pane {
  &.right {
    &::before {
      content: '';
      border: 6px solid transparent;
      border-top: 6px solid $clr-white;
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &.top {
    &::before {
      content: '';
      border: 6px solid transparent;
      border-top: 6px solid $clr-white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -12px;
    }
  }

  &.bottom {
    &::before {
      content: '';
      border: 6px solid transparent;
      border-top: 6px solid $clr-white;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }
  }
}

.seaz-crew__btn_box.seaz-charter__add_btn_box {
  @include tablet-small {
    flex-wrap: nowrap;
    justify-content: space-between;
    button {
      width: 48%;
    }
    .seaz-crew__btn {
      margin-right: 0;
    }
  }
  @include tablet-small {
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.seaz-charter__apa {
  &_form {
    width: 126.4rem;
    margin: 0 auto;
    padding-bottom: 4rem;

    @media screen and (min-width: 1920px) {
      width: 90%;
    }

    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 96%;
    }

    app-info-label {
      margin-bottom: 2rem;
      display: block;
    }

    .form__col-left {
      width: 100%;
      margin-right: 0;

      .form__row {
        width: 100%;
      }
    }

    .form__row,
    .form__cash {
      width: 50%;
      @include tablet {
        width: 100%;
      }
    }

    app-seazone-text-area {
      padding: 0;

      .form-field--content.form-field--content-outline {
        min-height: 11.5rem;
      }
    }

    app-expense-amount-form {
      .form__cash .form__row {
        width: 100%;
      }
    }

    .form__cash {
      .form__col {
        flex-grow: 0;
      }

      .form__col_currency {
        max-width: 50%;
        @include mobile {
          max-width: 100%;
        }
      }
    }
  }

  &_btns {
    width: 100%;
    padding: 3rem 0;
    border-top: 1px solid $seaz-border;

    .form__col {
      min-width: 12.5rem;
      width: auto;
      @include mobile {
        width: 49%;
        &:not(:last-child) {
          margin-right: 2%;
        }
      }
    }

    .form__col-left.form__row {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @include mobile {
        flex-direction: row;
      }
    }

    button {
      justify-content: center;
      height: 5rem;
    }

    .seaz-charter__btn_primary {
      padding: 0.6rem 1.5rem 0.9rem;
    }
  }

  &_navigation {
    .seaz-charter__list_nav {
      @include tablet {
        padding-bottom: 1px;
        justify-content: center;
        overflow: auto;
      }
    }

    .seaz-charter__list_nav_item {
      @include tablet {
        white-space: nowrap;
      }
    }

    @include mobile {
      overflow-x: auto;

      .seaz-charter__list_nav {
        width: 48rem;
        margin: 0 auto;
        overflow: initial;
      }
    }
  }

  &_radio {
    display: flex;
    flex-direction: column;

    &_label {
      padding-bottom: 1.2rem;
      color: $clr-primary-and-accent;
    }

    app-seazone-radio-item {
      display: flex;
      align-items: center;
      margin-bottom: 2.4rem;

      .form__radio-input:checked + label:before {
        color: $seaz-mulberry;
      }

      .form__radio-label:before {
        border-color: $seaz-mulberry;
        width: 2.4rem;
        height: 2.4rem;
      }

      .form__radio-label {
        padding: 0 0 0 4rem;
        height: 1.7rem;
      }

      .form__radio-input:checked + label:after {
        background-color: $seaz-mulberry;
        width: 1.2rem;
        height: 1.2rem;
        left: 6px;
        top: 6px;
      }

      .form__radio-label-text {
        color: $seaz-text;
      }
    }
  }

  &_department {
    margin-left: 3%;

    .form__row {
      border-left: 1px solid $seaz-border;
      width: 42% !important;
      margin-bottom: 0.4rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      @include tablet {
        width: 100%;
      }
    }

    &_text {
      color: $seaz-document-count;
      padding-bottom: 2.4rem;
    }

    @include mobile {
      margin-left: 0;
      padding-left: 1%;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.4rem 0 3.2rem;
    @include laptop {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &-petty-cash {
    max-width: 60%;
    border-color: #3f9795;
    @include laptop {
      max-width: 100%;
      width: 100%;
      margin-bottom: 2.4rem;
      order: 2;
    }
    @include tablet {
      .currency-list {
        display: flex;
      }
      .profile__col_box-price .profile__col-price {
        min-width: auto;
        margin-right: 0.8rem;
      }
    }
  }

  &-actions {
    margin-left: auto;
    @include laptop {
      width: 100%;
      order: 1;
      margin-bottom: 2.2rem;
    }
  }

  &_title {
    color: $clr-primary-and-accent;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 4.5rem;
  }

  &_table {
    background-color: $clr-white;
    border-radius: 1.6rem;
    padding: 2.4rem;

    .finance-search {
      margin-bottom: 0;
    }

    app-seazone-form-field .form-field--wrapper .form-field--content.large .form-field--content-prefix {
      @include mobile {
        max-width: 3.2rem;
      }
    }
  }
}

.seaz-apa-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.7rem;

  &__wrap {
    background-color: #e7e7eb;
    border-radius: 9px;
    padding: 0.2rem;

    @include laptop {
      min-width: 100%;
    }
  }

  .switch__btn {
    min-width: 13.8rem;
    flex-grow: 0;
    padding: 1.4rem 2.4rem;
    font-size: 1.3rem;

    &:not(:last-child) {
      margin-right: 0;
    }

    @include laptop {
      min-width: 50%;
    }
  }
}

.seaz-apa-report {
  &-view {
    display: flex;
    flex-direction: row;
    margin-bottom: 3.2rem;

    @include laptop {
      flex-direction: column;
      margin-bottom: 2.2rem;
    }
  }

  &-charts {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.seaz-apa-aside {
  .dashboard__aside {
    margin-bottom: 2.2rem;
  }

  .dashboard__aside-inner {
    padding: 2.4rem 3.2rem;
  }

  .text-left,
  .text-right {
    font-size: 1.4rem;
    line-height: 1.4;
    color: $clr-primary-and-accent;
    padding: 1.2rem 0;
    background-color: $clr-white;

    &.total {
      color: $clr-green;
    }
  }

  .text-right {
    font-weight: bold;
    white-space: nowrap;

    .combine-field {
      color: $seaz-document-count;
    }
  }

  .table {
    position: relative;
  }

  .table-hr {
    border-top: 1px solid $seaz-head-arrow;
  }

  .table__row.error td.total {
    color: $seaz-alarm;
  }

  .table__row td.apa-balance {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.seaz-apa-report-edit-modal {
  border-radius: 0;

  .modal__wrap {
    padding: 3rem;
  }

  .seaz-charter__apa_form {
    width: 100%;
  }

  .seaz-charter__apa_form {
    .form__row,
    .form__cash {
      width: 100%;
    }
  }

  .seaz-charter__apa_btns .form__col-left.form__row {
    justify-content: flex-end;
  }

  @include mobile {
    .seaz-charter__apa_btns .form__col-left.form__row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .form__col {
      width: 100%;
    }

    .seaz-charter__apa_btns .form__col:not(:last-child) {
      margin-right: 0;
    }
  }
}

.seaz-charter-form-label {
  width: 100%;
  max-width: 14rem;
  margin-right: 2.1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #010202;
}

.seaz-charter-form-control {
  width: 100%;

  &__field {
    margin-top: 1.6rem;
  }

  &__with-btn {
    display: flex;
    align-items: flex-start;

    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  &__btn-close {
    position: relative;
    width: 2.6rem;
    height: 2.4rem;
    margin-left: 0.3rem;
    z-index: 1;

    svg[fill],
    [width],
    [height] {
      width: 12px;
      height: 12px;
      fill: red;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: 1px solid #f5f5f5;
      border-radius: 50%;
      z-index: -1;
    }
  }

  .form-field--wrapper {
    width: 100%;
    max-width: 35rem;
  }
}

.seaz-charter__edit_form_select {
  &:not(:last-of-type) {
    margin: 3rem 0 0;
  }
}

.seaz-charter__edit_form_input {
  margin: 0.6rem 0 0;
}

.form-other-payments {
  margin-top: 3rem;

  .seaz-charter-form-control .form-field--wrapper {
    max-width: 56rem;
  }
}

.form-field-label {
  width: 100%;
  max-width: 14rem;
  display: flex;
  align-items: center;
  margin-right: 2rem;
  color: $seaz-text;
  line-height: 20px;
}

.seaz-charter__edit-vat {
  border-top: 1px solid $clr-grey;
  padding-top: 3rem;
  margin-bottom: 3rem;

  &__text {
    font-size: 16px;
    color: $seaz-text;
  }

  .form-field-label {
    padding-top: 1.5rem;
  }

  .seaz-charter__percentage-control {
    width: 100%;
  }
}

.seaz-charter__edit-control {
  .seaz-charter__edit_form_row {
    align-items: flex-start;
  }

  .seaz-charter-row {
    align-items: center;
  }

  .seaz-charter-input {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    .seaz-charter__edit_form_row,
    .seaz-charter-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .seaz-charter-input {
      width: 100%;
    }
  }

  .seaz-charter-charter-percentage-control {
    width: 100%;
  }
}

.seaz-charter-toggle {
  display: flex;
  margin-left: 1rem;

  .form-field__label {
    order: 1;
    white-space: nowrap;
    font-size: 1.6rem;
    color: #313247;
  }

  .seazone__toggle {
    margin-right: 1rem;
  }

  @media screen and (max-width: 768px) {
    margin: 1.7rem 0 0;
  }
}

.seaz-charter__edit-textarea {
  align-items: initial;

  .form-field__area .form-field--label {
    padding-top: 0;
    margin-right: 2rem;
  }
}

.seaz-charter__edit_calculation {
  width: 100%;

  &_wrapper {
    margin-top: 2rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $seaz-head-arrow;
    padding: 0.9rem 0;
    font-size: 1.2rem;

    &--title {
      color: $seaz-document-count;
    }

    &--amount {
      color: $seaz-text;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.85rem 0;

    span {
      font-weight: 500;
      color: $seaz-text;
    }
  }
}

.seaz-charter__edit-row-info {
  border-top: 1px solid $clr-grey;
  padding-top: 3rem;
  margin-top: 3rem;

  .seaz-h3 {
    margin-bottom: 1.5rem;
  }
}

.seaz-charter__edit_form-select {
  width: 10.2rem;
  margin-left: 0.8rem;

  .form-field--content-suffix[_ngcontent-seazoneServer-c208],
  .form-field--content-prefix[_ngcontent-seazoneServer-c208] {
    display: block;
  }
}

.seaz-charter-data-btn-wrap {
  justify-content: flex-end;
}

.statistic-daily .seaz-charter__filtering--num {
  color: $clr-green;
}

.statistic-term .seaz-charter__filtering--item:first-of-type .seaz-charter__filtering--text {
  color: $clr-primary-and-accent;
}

.statistic-daily .seaz-charter__filtering--item:first-of-type .seaz-charter__filtering--text {
  color: $clr-green;
}

.seaz-charter__edit_form-details {
  .seaz-charter__edit_form_row {
    align-items: flex-start;
  }

  .seaz__btn_small-outlined,
  .seaz__btn_small-outlined-plus {
    font-weight: 500;
  }

  .seaz__btn_small-outlined-plus::before {
    display: none;
  }

  .seaz-apa-btn {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    .seaz-apa-btn {
      width: 100%;
    }
  }
}

.seaz-charter-agent {
  width: 100%;
  background-color: $clr-white;
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin: 0 1.2rem 1.6rem 0;

  &__title {
    font-size: 1.4rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
  }

  &__list {
    margin-top: 1.2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    color: $seaz-about-text;

    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  &__icon {
    display: block;
    margin-right: 1rem;
    color: $seaz-edit-hover;
  }
}

.seaz-charter__guests_list_item_btn_box {
  button {
    margin-right: 1.3rem;
  }
}

.seaz-charter__guests_text {
  font-size: 1.6rem;
  font-weight: 500;
  color: $clr-primary-and-accent;
  word-break: break-all;

  @media screen and (max-width: 1024px) {
    font-size: 1.3rem;
  }
}

.seaz-charter-new-agent {
  width: 100%;

  &__container {
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: flex-start;

    .seaz-charter-form-label {
      margin-right: 1rem;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }

  &__btn-close {
    background-color: $seaz-document-count;
    border-radius: 50%;
    padding: 0.4rem;
    margin-left: 2.6rem;

    svg[width],
    [height] {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .form__block-text {
    width: 100%;
    max-width: 14rem;
    margin-right: 1rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #010202;
  }

  .phone-control-wrapper {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    &__item {
      flex-direction: column;

      .seaz-charter-form-label {
        margin: 0 0 1rem 0;
      }
    }

    .seaz-charter__guests_list_item_btn {
      width: 100%;
    }
  }
}

.seaz-charter-agent__row {
  display: block;
}

.seaz-charter-agent-box {
  padding: 7.3rem 0 4.4rem;

  &__wrapper,
  .seaz-charter__guests_add_title {
    width: 100% !important;
    max-width: 63.8rem;
    margin: 0 auto;
  }

  .seaz-charter-agent-form {
    padding: 0 4.4rem;
  }

  @media screen and (max-width: 768px) {
    .seaz-charter-agent-form {
      padding: 0;
    }
  }
}

.seaz-charter__guests_add_box.seaz-charter-agent-box {
  width: 100%;
}

.seaz-charter-agent-form {
  .seaz-charter__guests_add_form {
    width: 53.5rem;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.seaz-charter-broker,
.seaz-charter-notes {
  max-height: 25.6rem;
  padding: 0 2.4rem;
  overflow-y: auto;
  @include custom-scroll-bar;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $seaz-head-arrow;
    padding-bottom: 1.2rem;
    margin-bottom: 1.6rem;
    overflow: hidden;
  }

  &__title {
    font-weight: 500;
    color: $clr-primary-and-accent;
  }

  &__content {
    overflow: hidden;
  }

  &__list {
    margin-top: 1.6rem;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    color: $seaz-about-text;

    &--icon {
      display: block;
      font-size: 1.3rem;
      color: $seaz-edit-hover;
      margin-right: 1rem;
    }

    &--notes {
      max-width: 31rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 0.3rem;

      @media screen and (max-width: 1300px) {
        max-width: 21rem;
      }

      @media screen and (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }

  .boat-home__content {
    padding: 0;
    height: 15rem;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    max-height: 100%;
    overflow-y: visible;

    .boat-home__content {
      height: auto;
    }
  }
}

.seaz-charter-notes {
  padding: 0;

  &__text {
    word-break: break-word;
  }
}

.seaz-charter__edit_form-checkbox {
  width: 11rem;
  margin-left: 1rem;

  .form__checkbox-label-text {
    padding-left: 1rem;
  }

  .form__checkbox-label-text {
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    margin: 1.7rem 0 0;
  }
}

.seaz-charter__edit_payment .form-field-label-gross-income,
.form-field-label {
  @media screen and (max-width: 768px) {
    margin: 0 0 1rem;
  }
}

.seaz-charter__edit_payment .seaz-charter__edit_form-select {
  @media screen and (max-width: 768px) {
    margin: 1rem 0 0;
  }
}

.seaz-charter__edit_form-wrap {
  width: 76.6rem;
  margin: 0 auto 4.8rem;

  @include laptop {
    width: 100%;
  }
}

.seaz-charter__edit_payment {
  @media screen and (max-width: 768px) {
    .seaz-charter__edit_form_row {
      app-seazone-select,
      app-seazone-input,
      app-seazone-text-area {
        .form-field--label.form-field--label {
          @media screen and (max-width: 768px) {
            margin-bottom: 1rem;
          }
        }

        .form-field--wrapper__row {
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        }
      }
    }

    .form__row.form-other-payments {
      flex-direction: column;
    }
  }
}

.charter-complete-modal {
  .modal-close {
    top: 3.5rem;
  }

  .seaz-modal__apply_title {
    font-size: 2.4rem;
    width: 100%;
    text-align: left;
    line-height: 3rem;
    margin-bottom: 2rem;
  }

  .seaz-modal__apply_text {
    text-align: left;
  }
}

.seaz-charter__list_payment_main_list_icon {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-left: 0.6rem;
}

.seaz-charter__list_payment_main_list_btn {
  position: absolute;
  top: 50%;
  right: -1.6rem;
  transform: translate(0, -50%);
}

.seaz-charter-sorted {
  display: grid;
  grid-template-areas: 'search template';
  grid-template-columns: 1fr 33rem;
  align-items: flex-end;
  margin-bottom: 2.4rem;

  &__search {
    grid-area: search;
    position: relative;
    display: flex;
    margin-right: 3.1rem;
    width: 100%;
  }

  &__select {
    display: flex;
    width: 30.6rem;
    grid-area: select;
    justify-self: end;

    .form-field--wrapper {
      flex-direction: row !important;
      align-items: center;
    }

    .form-field--label {
      white-space: nowrap;
      margin-right: 1.6rem;
    }
  }

  &__download-template {
    grid-area: template;
    justify-self: end;
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
    grid-gap: 3rem 1rem;
  }

  @include tablet {
    &__select,
    &__search {
      width: 100%;
    }
  }

  @include mobile {
    grid-template-areas:
      'template'
      'search'
      'select';
    grid-gap: 2rem 0;

    .seaz-filters__row_search-icon {
      height: 4rem;
    }

    .seazone-form-field--wrapper .form-field--label {
      margin-bottom: 0;
    }
  }
}

.seaz-charter__guests_form-sortBy
  .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper
  .form-field--label {
  width: 27rem;
  max-width: 27rem;
  margin-right: 0.8rem;
  flex-shrink: 0;
}

@media screen and (max-width: 480px) {
  .seaz-charter__guests_form-sortBy .form-field--wrapper.form-field--wrapper__row {
    flex-direction: row;
  }

  .seaz-charter__guests_form-count .form-field--wrapper.form-field--wrapper__row {
    flex-direction: row;
  }

  .seaz-charter__guests_form-sortBy
    .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper
    .form-field--label {
    width: 30%;
    max-width: 30%;
  }

  .seaz-charter__guests_form
    app-seazone-input
    app-seazone-form-field
    .form-field--wrapper.form-field--wrapper__row
    .form-field--label {
    width: 15rem;
  }
}

.app-charter-filter-form {
  .form__row {
    align-items: center;
    margin-bottom: 0.8rem;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .form-field--wrapper .form-field--content-outline {
    height: 4rem;
  }

  .form-field--wrapper .form-field--content {
    align-items: center;
  }

  .select-placeholder {
    font-size: 1.4rem;
  }

  &__select {
    width: 24%;
    margin-right: 1.6rem;

    .select-placeholder,
    .select-value {
      font-size: 1.4rem;
    }

    .select-value {
      color: #010202;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 0 1.6rem;
    }
  }

  &__checkbox-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6.4rem;
    padding-top: 2.4rem;

    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: flex-start;
      padding-top: 0;
    }
  }

  &__checkbox {
    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }

    .form__checkbox-label {
      width: 2.4rem;
      height: 2.4rem;

      &:before {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 1.5rem;
      }
    }

    .form__checkbox-label-text {
      padding-left: 0.8rem;
    }
  }

  &__location {
    display: block;
    width: 24%;
    margin-right: 1.6rem;

    svg[width],
    [height] {
      width: 6px;
      height: 14px;
    }

    .form-field--content-suffix {
      transform: rotateY(180deg);
      transform-origin: 50%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 0 1.6rem;

      .form-field--wrapper {
        margin: 0 0 1.6rem;
      }
    }
  }

  &__btn {
    font-weight: 500;
    color: #00b9ff;
    text-transform: uppercase;
    padding-top: 2.4rem;

    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }
}

.app-charter-filter-form-location {
  &__wrap {
    width: 100%;
    max-width: 63.8rem;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    color: $clr-primary-and-accent;
    margin-bottom: 3rem;
  }

  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 14rem;
    margin-right: 2rem;

    &--icon {
      padding-left: 1.1rem;
    }
  }

  &__btn-box {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $seaz-head-arrow;
    padding-top: 3rem;
    margin-top: 3rem;

    .seaz__btn_rounded:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 3rem 0;
  }

  @media screen and (max-width: 662px) {
    .form__row,
    .form-field--wrapper.form-field--wrapper__row.wrapper-rounded {
      flex-direction: column;
    }

    .wrapper-rounded .form-field--label {
      margin: 0 0 0.8rem;
    }

    &__label {
      margin: 0 0 0.8rem;
    }
  }

  .form-field--content-outline {
    height: auto !important;
  }
}

.seaz-charter-edit-form-fields-container {
  display: flex;
  align-items: flex-end;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: space-between;

    &__app-seazone-input {
      margin-right: 1rem;
    }

    .seaz-charter__edit_form-select {
      flex-shrink: 1;
    }
  }
}

.seaz-charter__apa_btns-transaction .form__col-left.form__row {
  justify-content: flex-start;
}

.seaz-charter-financial-info {
  .boat__title {
    margin-bottom: 0.8rem;
  }

  .financial-data-table {
    display: block;
    background-color: #ffffff;
    border-radius: 1.6rem;
    padding: 2.4rem;
  }

  .boat__top-btn {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .boat__date-text:not(:last-child) {
      margin-right: 1.8rem;
    }
  }
}

.financial-report-table {
  .table__head {
    .table__col {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 120%;
      border-bottom: 1px solid $seaz-head-arrow;
      color: $seaz-document-count;
    }
  }

  .table__col-separator {
    padding-bottom: 1.2rem;
  }

  .table__body .table__col {
    padding: 0.8rem;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;

    &.table__col_details {
      padding: 0;
    }

    &:first-of-type {
      border-radius: 1.2rem 0 0 1.2rem;
    }

    &:last-of-type {
      border-radius: 0 1.2rem 1.2rem 0;
    }
  }
}

.seaz-charter-financial-info-table,
.seaz-charter__apa_table {
  .table__col-inner-description {
    width: 37.2rem;
  }

  .table__col-inner {
    max-width: 220px;
  }

  @media screen and (max-width: 1300px) {
    .table__col-inner {
      max-width: 150px;
    }

    .table__col-inner-description {
      width: 8.2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .table__col-inner-description {
      width: 100%;
    }

    .table__col-inner-financial {
      width: 60px;
    }

    .boat__date-text:not(:last-child) {
      margin-right: 1.8rem;
    }
  }
}

.seaz-charter-financial-info-table {
  .table_financial .table__head .table__col,
  .table__col-btn-sort {
    color: #828282;
  }
}

.seaz-charter-additional-details {
  margin: 2.4rem 0 0;

  &__content {
    display: flex;
    justify-content: space-between;
    padding: 2.4rem 3rem;
  }

  &__col {
    width: 50%;

    &:not(:last-of-type) {
      border-right: 1px solid $seaz-head-arrow;
    }
  }

  &__list {
    padding: 2rem 0;
    margin: 0 3.2rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid $seaz-head-arrow;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 4.1rem;
    color: $seaz-about-text;

    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
    }

    &--sign {
      padding-right: 0.8rem;
    }

    &--last-item {
      font-weight: bold;
    }
  }

  &__title_wrapper {
    display: flex;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
  }

  @media screen and (max-width: 1024px) {
    &__content {
      padding: 2rem 2rem;
    }

    &__list {
      margin: 0 1.5rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-head-arrow;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      flex-direction: column;
    }

    &__col {
      width: 100%;

      &:not(:last-of-type) {
        border-right: 0;
        border-bottom: 1px solid $seaz-head-arrow;
      }
    }

    &__list {
      margin: 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-head-arrow;
      }
    }

    &__item {
      padding-left: 3rem;
    }

    &__icon {
      font-size: 1.5rem;
    }
  }
}

.seaz-charter-broker-modal {
  width: 95%;

  .seaz-charter__guests_list {
    width: 95%;
  }

  .seaz-charter__guests_list_item_col {
    width: 21%;
  }

  @media screen and (max-width: 1024px) {
    .seaz-charter__guests_list_item_col {
      width: 19%;
    }
  }

  @media screen and (max-width: 768px) {
    .seaz-charter__guests_list_item_col {
      width: 100%;
      padding-bottom: 1.6rem;
    }

    .seaz-charter__guests_list_item {
      padding-bottom: 4rem;
      flex-direction: column;
    }
  }
}

.seaz-charter-overview-container {
  margin: 0 0 3rem;
}

.seaz-charter-overview {
  width: 100%;
  max-width: 126.4rem;
  padding: 3rem 1rem 0;
  margin: 0 auto;
  border-top: 1px solid $clr-grey;

  &__component {
    margin-top: 3rem;
  }

  &__services {
    margin-bottom: 3.4rem;

    &--btn-wrap {
      display: flex;
      justify-content: flex-end;
    }

    &--wrap {
      display: flex;
      align-items: flex-end;
      margin-top: 3rem;
    }
  }

  &__tabs-wrap {
    margin-right: 2.4rem;

    .switch__btn {
      padding: 1.3rem 1.6rem;
    }
  }

  &__selecting {
    display: flex;
    margin-right: 2.6rem;

    &--item {
      width: 100%;
      min-width: 36.8rem;

      &:not(:last-of-type) {
        margin-right: 2.4rem;
      }
    }
  }

  &__selecting-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 36.8rem;

    @media screen and (max-width: 1300px) {
      width: 100%;
      min-width: 28rem;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      min-width: 100%;
    }
  }

  &__selecting-item {
    width: 100%;

    &--month {
      padding-right: 0.8rem;
    }
  }

  .seaz-charter-overview__selecting-item app-seazone-form-field .seazone-form-field--wrapper .form-field--label {
    margin: 0 0 0.4rem;
    font-size: 1.2rem;
    line-height: 14px;
    color: $clr-purple;
  }

  &__btn {
    font-weight: 500;
  }

  &__btn-download {
    padding: 1.1rem 2.2rem;
    border: 1px solid #351f73;
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $light-color;
    color: $clr-primary-and-accent;
  }

  &__btn-apply {
    height: 4.7rem;
    padding: 0 3rem;
    margin-left: 2.4rem;
    border: 1px solid #351f73;
    box-sizing: border-box;
    border-radius: 0.4rem;
    transition:
      background-color 0.2s,
      color 0.2s;

    &:disabled {
      border-color: transparent;
      color: $clr-white;
    }
  }

  &__toggle-box {
    margin-left: 3rem;
  }

  &__toggle {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }

    &--text {
      color: #010202;
      margin-left: 0.8rem;
    }
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
    max-width: 100rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 1rem 0;
    border: none;

    &__services {
      flex-direction: column;
      align-items: flex-start;

      &--wrap {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 1.6rem;
      }
    }

    &__toggle-box {
      margin: 2rem 0 0;
    }

    &__btn-download {
      width: 100%;
      text-align: center;
    }

    .seaz-charter__btn_primary {
      justify-content: center;
    }

    .seaz-tabs-container {
      width: 100%;
    }

    &__tabs-wrap {
      display: flex;
      margin-right: 0;

      .switch__btn {
        width: 100%;
      }
    }

    &__selecting {
      width: 100%;
      flex-direction: row;
      margin: 0 0 2.2rem;

      &--item {
        width: 100%;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }

    &__btn-apply {
      width: 100%;
      margin: 1.6rem 0 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__services {
      app-duration-mode-control {
        width: 100%;
      }

      .duration-tabs {
        width: 100%;
      }

      .duration-mode-selecting__list,
      .duration-mode-selecting__item {
        width: 100%;
      }

      .duration-tabs__switch-btn {
        width: 50%;
        text-align: center;
      }
    }
  }
}

.seaz-tabs-wrap__title {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.2rem;
  color: $clr-purple;
}

.seaz-charter-overview-chart {
  display: flex;
  justify-content: space-between;

  &__container {
    border: 0.5px solid #e7e7eb;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    padding: 2.4rem;
    background-color: #fff;
  }

  &__chart-item {
    flex-grow: 1;
  }

  &__chart-total {
    width: 32.6rem;
    margin-left: 1.6rem;

    &--income {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      color: $clr-main;
      margin-top: 0.8rem;
    }
  }

  &__chart-title-box {
    margin-bottom: 3rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__header--title {
    font-size: 2.4rem;
    font-weight: bold;
    color: $clr-purple;
    margin-bottom: 0.8rem;
    line-height: 1.4;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__header--sub-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: $clr-main;
    margin-top: 0.8rem;
  }

  &__chart-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: $clr-purple;
    margin-bottom: 0.8rem;
    line-height: 1.4;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__chart--title-val {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
    color: $clr-main;
  }

  &__profit-chart {
    width: 100%;
    max-width: 43.8rem;
    padding: 0;

    &--title-box {
      padding: 2rem 2.4rem;
      border-bottom: 1px solid $clr-ashy-grey;
      margin-bottom: 1.7rem;
    }

    &--title {
      margin-bottom: 0;
    }

    &--box-chart {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &__chart-item,
    &__chart-total {
      width: 100%;
      max-width: 100%;
    }

    &__chart-total {
      flex-shrink: 0;
      margin: 1.6rem 0 0;
    }

    &__container {
      padding: 1.5rem 1rem;
      overflow: hidden;
    }
  }
}

.seaz-charter-overview-graph {
  &__selecting-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 4.8rem;
  }

  .seaz-charter-overview__selecting-item {
    &:not(:last-of-type) {
      margin-right: 1.7rem;
    }
  }

  &__selecting-days {
    width: 27.5rem;
  }

  &__selecting-year {
    width: 10.7rem;

    .form-field--content {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translate(0, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #46a6a4;
      }
    }

    .form-field--content.form-field--content-outline {
      position: relative;
      padding: 0.8rem 1.2rem 0.8rem 2.5rem;
    }
  }

  &__graph {
    margin-top: 2.4rem;
  }

  @media screen and (max-width: 768px) {
    &__selecting-list {
      flex-direction: column;
      margin-top: 1.9rem;
    }

    &__selecting-days,
    &__selecting-year {
      width: 100%;
    }

    .seaz-charter-overview__selecting-item {
      &:not(:last-of-type) {
        margin: 0 0 1.2rem 0;
      }
    }
  }
}

.seaz-charter-overview-graph__selecting-year-purple {
  .form-field--content {
    &:after {
      background-color: $clr-purple;
    }
  }
}

.seaz-charter-overview-trips-table {
  padding-bottom: 8.4rem;

  .table__col {
    color: #010202;
  }

  .table__col-inner {
    overflow: visible;
    white-space: normal;
  }

  .table_financial .table__col-inner {
    max-height: initial;
  }

  .table-period {
    color: $seaz-document-count;
  }

  .table__col-net-income {
    display: flex;
  }

  .net-income-badge {
    margin-right: 0.5rem;

    &.error {
      color: $seaz-alarm;
    }

    &.value-success {
      color: $seaz-success;
    }
  }

  .seaz-charter-overview-chart__chart-title {
    margin-bottom: 3rem;
  }

  .seaz-charter__apa_table {
    border: 0.5px solid #e7e7eb;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgb(0 0 0 / 4%);
  }

  .table__col.financial-data__table_btn {
    width: 8.8rem;
  }

  .financial-data__table_btn .form__btn {
    width: 1.6rem;
    margin-left: auto;
  }

  .main-btn_green {
    background: inherit;
    border: none;
  }

  .main-btn_green.main-btn_form-icon {
    color: #55b5b3;
    transform: rotateY(190deg);
  }

  .financial-data__table_medium app-seazone-form-field {
    max-width: 100%;
  }

  .select-placeholder,
  .select-value {
    font-size: 1.2rem;
    color: #010202;
  }

  .table .form-field--content.form-field--content-outline {
    height: 2.9rem;
    padding: 0 1.2rem;
  }

  .table .seazone__form-input {
    font-size: 1.2rem;
    color: #010202;

    &::placeholder {
      font-size: 1.2rem;
      color: #010202;
    }
  }

  .table {
    .form-field--label {
      font-size: 1.2rem;
      color: #828282;
    }
  }

  .table_financial .financial-data__table_row .table__col {
    padding: 2rem 0.5rem;
  }

  .table__row.financial-data__table_row {
    border-bottom: 1px solid #e0e0e0;
  }

  .table_financial .table__body .table__col {
    background: inherit;
  }

  .table__body {
    .table__row {
      border-bottom: 1px solid #e0e0e0;
    }

    .table__col {
      padding: 1.3rem 0.8rem 2rem;
    }
  }

  .financial-data__table .table__col-inner,
  .seaz-charter__apa_table {
    width: max-content;
  }

  .financial-data__category .table__col-inner,
  .seaz-charter__apa_table {
    width: auto;
  }

  .app-charter-filter-form__location {
    display: inline-block;
    width: 100%;
  }

  .table_financial .table__col-btns {
    border-left: none;
  }

  &__reload-btn {
    display: block;
    margin: 3.8rem auto 2.4rem;
  }

  .seaz-charter-card-badge {
    margin: 0;
    text-align: center;
    line-height: 1rem;
  }

  @media screen and (max-width: 768px) {
    .app-charter-filter-form__location {
      margin: 0;
    }

    .app-charter-filter-form__location .form-field--wrapper {
      margin: 0;
    }

    .seaz-charter-card-badge {
      padding: 0.7rem 1.2rem;
    }
  }

  .table__col app-seazone-form-field .seazone-form-field--wrapper .form-field--label {
    font-size: 1.2rem;
    line-height: 20px;
    color: $clr-light-grey;
    margin-right: 0;
  }

  .net-income-range .form-field--content.form-field--content-outline {
    min-width: 10rem;
  }
}

.seaz-charter-contact-person {
  &__btn-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.4rem;
  }

  .info-label.light-purple {
    margin-bottom: 2.4rem;
  }

  .seaz-charter__guests_form_count_btn {
    border-radius: 0.4rem;
  }

  .seaz-charter__guests_form_table_item {
    padding-right: 0.8rem;
  }

  .seaz-charter__guests_form_table_head--name,
  .seaz-charter__guests_form_table_item--name {
    width: 14.5%;
  }

  .seaz-charter__guests_form_table_head--phone,
  .seaz-charter__guests_form_table_item--phone,
  .seaz-charter__guests_form_table_head--email,
  .seaz-charter__guests_form_table_item--email {
    width: 32%;
  }

  .seaz-charter__guests_form_table_head--notes,
  .seaz-charter__guests_form_table_item--notes {
    width: 15%;
  }

  .seaz-charter__guests_form_table_head--icon {
    display: inline-block;
    vertical-align: middle;
  }

  .seaz-charter__guests_text,
  .seaz-charter__guests_form_table_item_text {
    cursor: pointer;
    display: inline-block;
  }

  .seaz-charter__guests_form_table_item--services {
    flex-direction: row;
    justify-content: space-between;
    width: 8%;
  }

  @media screen and (max-width: 1024px) {
    .seaz-charter__guests_form_table_item--name,
    .seaz-charter__guests_form_table_item--phone,
    .seaz-charter__guests_form_table_item--email,
    .seaz-charter__guests_form_table_item--services {
      width: 100%;
    }

    .seaz-charter__guests_form_table_item--services {
      justify-content: flex-start;
    }

    .seaz-charter__guests_form_table_item {
      margin: 0 0 2rem 0;
    }
  }
}

.seaz-charter__persons_head_tooltip,
.seaz-charter__persons_notes_tooltip {
  box-shadow: 0 0 12px rgb(53 31 115 / 22%);

  .tooltip {
    padding: 1.2rem;
    border-radius: 4px;
    position: relative;
    display: inline-block;
    box-sizing: content-box;

    .tooltip__text {
      color: $seaz-text;
      letter-spacing: 0.01em;
    }
  }
}

.seaz-charter__persons_head_tooltip.tooltip-pane {
  &.top .tooltip::before {
    transform: translateX(calc(-50% - 6px));
  }

  &.bottom .tooltip::after {
    transform: translateX(calc(-50% - 6px)) rotate(180deg);
  }
}

.seaz-charter-persons-form-modal {
  .seaz-charter__guests_list {
    width: 100%;
  }

  .seaz-charter__guests_list_item_col {
    width: 18.5%;
  }

  .seaz-charter__guests_list_item_label {
    margin-bottom: 0.8rem;
  }

  .seaz-charter__guests_list_item_btn--added {
    margin-right: 0;
  }
}

.seaz-charter-overview-income {
  &--title-box {
    display: flex;
    justify-content: space-between;
  }

  &--title-val {
    font-size: 2.4rem;
    font-weight: bold;
    color: $clr-purple;
    line-height: 1.4;
  }
}

.value-success {
  color: $seaz-success;

  &.error {
    color: $seaz-alarm;
  }

  &.zero {
    color: $clr-light-grey;
  }
}

.download-template {
  margin-left: 1.6rem;
  text-align: right;

  &__button-group {
    display: flex;
    align-items: center;
  }

  &__button {
    padding: 0.9rem 2rem;

    &:not(:first-of-type) {
      margin-left: 1.6rem;
    }
  }

  &__button-download-template {
    position: relative;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $seaz-primary-and-accent;
    margin-bottom: 2.4rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1rem;
      background-color: $seaz-primary-and-accent;
      transition: transform 0.3s;
    }

    &:hover {
      &:before {
        transform: translateY(0.1rem);
      }
    }
  }

  @include mobile {
    width: 100%;

    &__button-group {
      flex-direction: column;
      width: 100%;
    }

    &__button {
      width: 100%;

      &:not(:first-of-type) {
        margin: 1.6rem 0 0;
      }
    }
  }
}

.simple-header-charter {
  &.boat__card-wrap {
    width: auto;
  }

  .profile__block {
    display: flex;
    align-items: center;
  }
}

.charter-general-notes-box {
  // margin-left: 1.6rem;
  margin-top: 1.6rem;
}

.charter-list-dashboard {
  app-charter-agent,
  app-charter-documents,
  app-charter-contact-person,
  app-charter-general-notes,
  .seaz-charter__list-board {
    height: 100%;
  }
}

.charter-trips {
  flex-direction: row;

  .charter-general-notes-box {
    margin: 0 0 0 1.6rem;
  }

  @include laptop {
    flex-direction: column;

    .charter-general-notes-box {
      margin: 1.6rem 0 0;
    }

    .seaz-charter-broker {
      padding: 0.5rem;
    }

    .seaz-charter-notes {
      padding: 0 1.5rem;
    }
  }
}

.general-notes-content {
  padding: 2rem 0;

  .seaz-charter-notes {
    padding: 0 2.4rem;
  }
}

.charter-boarding-modal-text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-document-count;
  margin-top: 1.2rem;
}

.charter-boarding-modal-form {
  margin-top: 3rem;

  .seazone__text-area {
    font:
      1.4rem/1.6rem 'Roboto',
      sans-serif !important;
  }
}

.charter-boarding-modal-form-footer {
  @include footer-with-border-left-btn(3rem 0 2rem 0, 4rem 0 0 0);
  gap: 1.2rem;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    @include footer-with-border-left-btn(2rem 0, 2rem 0 0 0);

    .form-footer-btn {
      width: 100%;
    }
  }
}

.boat-preference-list-card-header {
  @include d-items-end;
  align-items: center;
  padding-bottom: 1.2rem;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #f5f5f5;
  }

  &__status {
    display: flex;
    align-items: center;

    .boat-preference-list-card__info-title {
      min-width: 6rem;
      margin-bottom: 0;
    }
    &-date {
      min-width: 8rem;
    }
  }
}

.boat-preference-list-card-content__col {
  &--change-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &--status {
    display: flex;
    align-items: center;
    .boat-preference-list-card__info-title {
      min-width: 8rem;
      margin-right: 0.8rem;
    }
  }
  &:not(:last-child) {
    @include tablet {
      padding: 1.2rem 0;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}

.boat-preference-list-card {
  display: block;
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
    color: $clr-primary-and-accent;
  }

  &__info-title {
    display: inline-block;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    margin-bottom: 0.4rem;
  }
}

.boat-preference-list-card-content {
  $row-gap: 2.4rem;
  @include box-grid(1.5fr 2fr calc(8.8rem - $row-gap), initial, initial, 0 $row-gap);

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;

    &.seaz-alarm {
      color: $seaz-alarm;
    }
  }

  .boat-maintenance-task-view-modal-header__status-change {
    display: block;
    max-width: 12rem;

    .status-select-container {
      max-width: 12rem;
    }
  }

  @include tablet {
    @include box-grid(1fr, initial, initial, 1.2rem 0);

    &__col {
      @include d-align-items-center;
      width: 100%;

      &--status {
        align-items: baseline;
      }

      &:not(:first-of-type) {
        border-left: 0;
        padding-left: 0;
      }
    }

    &__text {
      width: 100%;
    }

    &__title {
      min-width: 11.4rem;
      margin-right: 0.4rem;
    }
  }
}

.boat-preference-list-card-status-label {
  @include typography(1.2rem, 500, 1.44rem);
  padding: 0.5rem 0.8rem;
  border-radius: 1.8rem;
  background-color: $clr-primary-and-accent;
  color: $clr-white;
  white-space: nowrap;
  margin: 0 0.8rem;

  &--completed {
    background-color: #3aae27;
  }
  &__wrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
}

.boat-preference-list-card-footer {
  @include d-flex-space-between;
  padding: 1.6rem 2.4rem;
  @include laptop {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.6rem;
  }
  &__actions {
    display: flex;
    @include laptop {
      width: 100%;
    }
    @include tablet {
      flex-wrap: wrap;
    }
  }
  .boat-preference-list-card-footer__btn {
    @include tablet {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
}

.boat-preference-list-card-footer__toggle {
  display: flex;
  margin-right: 1.6rem;
  min-width: 10.4rem;
  @include tablet {
    margin-bottom: 1.6rem;
    flex: 1 1 100%;
  }
}

.boat-preference-list-card-footer__actions__buttons {
  display: flex;
  width: 100%;
}

.boat-preference-list-card-footer__info {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  @include laptop {
    flex-direction: column;
    align-self: flex-start;
    margin-bottom: 1.2rem;
    .boat-preference-list-card-info-task__row {
      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }
      .boat-preference-list-card-info-task__text {
        min-width: 8.8rem;
      }
    }
  }
}

.boat-preference-list-card-header-list-name {
  display: flex;
  flex-direction: column;
}

.charter-preference-list {
  display: grid;
  gap: 2.4rem;
  @include tablet {
    gap: 1.6rem;
  }

  &-wrapper {
    padding: 2.4rem 0;
    @include tablet {
      padding: 2.8rem 0;
    }

    &__actions {
      @include d-flex-end;
      margin-bottom: 2.4rem;
      @include laptop {
        flex-direction: column;
        &__info {
          margin-bottom: 1.2rem;
        }
      }
      @include tablet {
        margin-bottom: 1.6rem;
        .charter-preference-list__add-button {
          width: 100%;
        }
      }
    }
    &__actions__buttons {
      display: flex;
    }
    &__actions__info {
      display: inline-flex;
      align-items: center;
      margin-right: 1.2rem;
      &-text {
        @include typography(1.2rem, 500, 1.6rem);
      }
    }
  }

  &__options-button {
    margin-right: 1.6rem;
  }

  .form-field__label {
    color: $seaz-text;
  }
}

.charter-preference-list__view {
  color: $seaz-text;
  &__wrapper {
    display: grid;
    gap: 3.2rem;
    padding: 0 5rem;
    overflow: hidden;
    @include tablet {
      padding: 0;
    }
  }
  &__title {
    @include typography(3rem, 700, normal);
    color: $clr-primary-and-accent;
  }
  &__subtitle {
    @include typography(1.6rem, 500, normal);
    color: $seaz-text;
    margin-top: 1.6rem;
  }
  &__section {
    display: grid;
    gap: 2.4rem;
    break-inside: avoid;
    page-break-inside: avoid;
  }
  &__section__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem 1.6rem;
    &--partial {
      grid-template-columns: repeat(2, 1fr);
    }
    @include tablet {
      grid-template-columns: 1fr !important;
    }
  }
  &__section__title {
    @include typography(2.4rem, 500, 3.48rem);
  }
  &__item {
    display: grid;
    gap: 1.2rem;
    break-inside: avoid;
    page-break-inside: avoid;
  }
  &__full-width-item {
    grid-column: 1 / -1;
  }
  &__item__title {
    @include typography(1.4rem, 400, normal);
  }
  &__item__value {
    @include typography(1.6rem, 700, normal);
  }
  &__header-space {
    height: 25px;
  }
  &__footer-space {
    height: 75px;
  }
  &__footer {
    display: none;
    position: fixed;
    bottom: 25px;
    left: 25px;
    right: 25px;
    &__img {
      position: relative;
      width: 100%;
      height: 20px;
      img {
        object-fit: contain;
      }
    }
  }

  &__table {
    display: grid;
    gap: 1.2rem;
    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &__notes {
    grid-column: 2 / -1;
    @include tablet {
      grid-column: 1;
    }
  }
}

@media print {
  body {
    visibility: hidden;
  }
  app-root {
    overflow: auto;
    position: relative;
  }

  .seaz-charter__container {
    width: fit-content;
  }

  app-loader,
  .loader-wrapper,
  app-header,
  app-footer,
  app-boat-details-nav,
  app-boat-charter-header,
  app-quick-nav-button {
    display: none !important;
  }
  .main-wrap,
  .main-wrap__scrollable {
    overflow: auto !important;
  }
  .charter-preference-list__view {
    visibility: visible;
    &__wrapper {
      padding: 0 2.5rem;
    }
  }
  .charter-preference-list__view__footer {
    display: block;
  }
  .not-printable {
    display: none;
  }
}
