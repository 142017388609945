.charter-fee-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 328px 228px;
  gap: 16px;
  margin-top: 40px;

  & .charter-contract {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    min-width: 30rem;
  }

  & .broker-fee {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    min-width: 30rem;
  }

  & .installments {
    grid-column: 3 / 5;
    grid-row: 1 / 3;
  }

  & .charter-owner-fee {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    .charter-contract {
      order: 1;
    }

    .installments {
      order: 2;
    }

    .broker-fee {
      order: 3;
    }

    .charter-owner-fee {
      order: 4;
    }
  }
}

.charter-fee-block {
  display: flex;
  flex-direction: column;
  border-radius: 1.6rem;
  background: $light-color;
  box-shadow: 0 2px 2px 0 rgba(43, 28, 54, 0.16);

  &__header {
    height: 60px;
    padding: 1.6rem 2rem;
    border-bottom: 1px solid $seaz-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    color: $clr-primary-and-accent;
  }

  &__edit {
    padding: 0.6rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }
}

.charter-fee-block, .charter-fee-list__form {
  .accordion {
    .accordion__btn {
      transition: transform 0.3s ease;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    &.active {
      .accordion__btn {
        transform: rotateZ(180deg);
      }
    }
  }
}

.charter-fee-installments, .charter-fee-list {
  &__modal {
    &.seaz-modal.seaz-rounded {
      padding: 0;

      & .modal__wrap {
        padding: 0;
      }

      & .modal-close {
        top: 1.6rem;
        right: 1.6rem;
      }
    }

    &-header {
      padding: 1.6rem 4rem 1.6rem 1.6rem;
      border-bottom: 1px solid $seaz-bg;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
      color: $clr-primary-and-accent;
    }
  }
}
.charter-fee-installments {
  height: 100%;

  &__body {
    margin: 1.6rem 0;
    overflow-y: auto;
    @include custom-scroll-bar;
  }

  &__stats {
    display: table;

    &-item {
      display: table-row;

      &:not(:last-child) {
        & .charter-fee-installments__stats-col {
          padding-bottom: 2.4rem;
        }
      }
    }

    &-col {
      display: table-cell;

      &:nth-child(2) {
        padding-left: 1.6rem;
        width: 100%;
      }
    }

    &-left {
      display: flex;
      justify-content: space-between;
      gap: 1.2rem;
      flex-wrap: nowrap;
      padding: 0.4rem 0.8rem;
      background: $seaz-bg;
      font-size: 1.4rem;
      color: $clr-primary-and-accent;
      border-radius: 2px;
      white-space: nowrap;
    }

    &-value {
      text-align: right;
      min-width: 8rem;
    }

    &-right {
      position: relative;
      height: 2.4rem;
    }

    &-bar {
      position: absolute;
      width: 100%;
      height: 2.4rem;
      border-radius: 0.4rem;
      top: 0.4rem;
      min-width: 54px;

      &--total {
        background: $clr-primary-and-accent;
      }

      &--pending {
        background: $yellow-color;
      }

      &--paid {
        background: $seaz-success;
      }
    }

    &-percentage {
      position: relative;
      color: $light-color;
      padding: 0.8rem;
      font-size: 1.2rem;
      font-weight: 500;
      z-index: 1;

      &.no-value {
        color: $clr-primary-and-accent;
        min-width: auto;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-wrapper {
      position: relative;
      padding-top: 3rem;
    }
  }

  &__col {
    color: $seaz-text;
    font-size: 1.4rem;

    &--head {
      color: $clr-primary-and-accent;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 1.2rem;
    }

    &--label {
      color: $seaz-about-text;
      min-width: 12rem;
      width: 12rem;
      padding: 1.2rem 0;
      vertical-align: middle;
    }

    &--value {
      min-width: 14rem;
      padding: 1.2rem;
    }

    &--notes {
      width: calc(75% - 1rem);
      padding: 1.2rem 0 1.2rem 1.2rem;
    }
  }

  &__status {
    color: $light-color;
    font-size: 1.6rem;
    line-height: 120%;
    padding: 0.6rem 1.2rem;
    border-radius: 1.8rem;
    display: inline-block;
  }

  &__form {
    padding: 0 1.6rem;
    width: auto;

    .status-select-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6rem 1.2rem;
      border-radius: 1.8rem;
      box-sizing: border-box;
    }

    .charter-fee-installments__col--value {
      padding-bottom: 2.4rem;
    }

    app-seazone-text-area .wrapper-rounded .form-field--content {
      height: 11.5rem;
    }

    &-error {
      display: flex;

      .form__block-error {
        position: static;
        margin: 0;
        text-align: end;
        font-size: 1.4rem;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 1.6rem;
    justify-content: flex-end;
    margin: 1.6rem 0;
  }
}

.charter-fee-list {
  &__body {
    margin: 1.6rem 0;
    padding: 0 1.6rem;
    overflow-y: auto;
    @include custom-scroll-bar;
  }

  &__divider {
    margin: 1.6rem 0;
    height: 1px;
    background: $seaz-border;
  }

  &__form {
    margin: 1.6rem 0;
    width: auto;
    max-height: 80%;
    overflow: hidden;

    &-scroll-container {
      overflow: auto;
      @include custom-scroll-bar;
      max-height: 55vh;
      padding: 0 1.6rem;
    }
  }

  &__actions {
    padding: 0 1.6rem;
    display: flex;
    gap: 1.6rem;
    justify-content: flex-end;
    margin: 1.6rem 0;

    &-spacer {
      display: none;
    }
  }

  &__add-broker {
    gap: .8rem;
    display: flex;
    margin-right: auto;
  }

  @include tablet-small {
    &__actions {
      flex-direction: column;
      justify-content: stretch;
      &-spacer {
        display: block;
        border-bottom: 1px solid $seaz-border;
      }
    }

    &__add-broker {
      margin: 0;
    }
  }
}

.charter-fee-item {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
  }

  &__title {
    display: flex;
    align-items: center;
    color: $seaz-text;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.6rem;
    gap: .8rem;

    span {
      color: $seaz-border;
      font-size: 1.4rem;
    }
  }

  &__block {
    &-row {
      padding: 1.2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.6rem;
      &--thin {
        padding: 0;
      }
    }

    &-name,
    &-label,
    &-value,
    &-additional {
      font-size: 1.4rem;
      line-height: 14px;
    }

    &-name {
      color: $seaz-text;
      &--bold {
        font-weight: 500;
      }
    }

    &-label {
      color: $seaz-about-text;
    }

    &-value {
      font-weight: bold;
      color: $green-grey;
      white-space: nowrap;
    }

    &-additional {
      font-weight: 500;
      color: $seaz-about-text;
    }

    &-total {
      color: $seaz-about-text;
      font-size: 1.6rem;
      font-weight: 500;
    }

    &-input {
      width: 50%;

      input {
        text-align: right;
      }
    }
  }

  &__accordion-content {
    padding-left: 1.6rem;
  }

  .accordion {
    &__label {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
      cursor: pointer;

      &_text {
        width: 100%;
      }

      &_right {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}


.charter-fee-contract {
  height: 100%;
  padding-bottom: 20px;

  & .charter-fee-block__header {
    margin-bottom: 16px;
  }

  & .charter-contract-block {
    max-height: 234px;
    overflow: auto;
    padding: 0px 20px;
    @include custom-scroll-bar;

    @media screen and (max-width: 1300px) {
      max-height: 100%;
    }

    &__header {
      color: $clr-light-grey;
      font-size: 12px;
      display: flex;
      gap: 24px;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-box {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &-accordion-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 16px;
        padding-left: 16px;

        .charter-contract-block__info-title {
          color: $seaz-text;
        }
      }

      &-accordion {
        width: 100%;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        .accordion__label {
          display: flex;
          align-items: center;
          gap: 8px;

          &_text {
            width: 100%;
          }
        }
      }

      &-title {
        color: $seaz-about-text;
        font-size: 14px;

        &.medium {
          font-weight: 500;
        }
      }

      &-value {
        color: $clr-green;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}

.charter-fee-owner-net {
  height: 100%;
  padding-bottom: 20px;

  & .charter-fee-block__header {
    margin-bottom: 16px;
  }

  & .charter-owner-net-fee-block {
    max-height: 209px;
    overflow: auto;
    padding: 0px 20px;
    @include custom-scroll-bar;

    @media screen and (max-width: 1300px) {
      max-height: 100%;
    }

    &__header {
      width: 100%;
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr 60px 100px;
      margin-bottom: 18px;

      .spacer {
        display: block;
        width: 100%;
      }

      span {
        text-align: end;
      }
    }

    &__info {
      display: flex;
      gap: 24px;
      flex-direction: column;

      &-accordion-content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 16px;
        padding-left: 16px;
      }

      &-accordion-content .charter-owner-net-fee-block__info-title {
        color: $seaz-text;
      }

      &-item {
        display: grid;
        gap: 12px;
        grid-template-columns: 1fr 60px 100px;

        &.accordion-wrapper {
          grid-template-columns: 1fr;
        }

        .accordion__label {
          display: flex;
          align-items: center;
          gap: 8px;

          &_text {
            width: 100%;
          }
        }
      }

      &-item-flex {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;

        .medium {
          font-weight: 500;
        }
      }

      &-title {
        color: $seaz-about-text;
        font-size: 14px;

        &.total {
          font-weight: 500;
          font-size: 16px;
        }
      }

      &-percent,
      &-value {
        color: $clr-green;
        font-weight: 700;
        font-size: 14px;
        text-align: end;
      }
    }
  }
}

.charter-fee-list {
  height: 100%;
}
